import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { updateComplaints } from "../../redux/notificationsSlice";

import axios from "axios";

import { parseAxiosError } from "../../utils/AxiosUtils";
import { loadUser } from "../../utils/AuthDataUtils";

import {
  formatPhoneString,
  realizationOptions,
  whenOptions,
  whoOptions,
} from "../../utils/StringUtils";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
} from "reactstrap";
import {
  FaUser,
  FaCar,
  FaTools,
  FaExclamationCircle,
  FaPaperclip,
  FaPlus,
  FaRegTimesCircle,
  FaArrowRight,
} from "react-icons/fa";

import CustomButton from "../../components/CustomButton";
import CustomText from "../../components/CustomText";
import CustomAlert from "../../components/CustomAlert";

import Colors from "../../constants/Colors";

const fieldsConfig = [
  // Klient
  {
    name: "client",
    label: "Numer telefonu klienta",
    hint: null,
    required: true,
    group: "Klient",
    type: "text",
    value: "+48",
  },
  {
    name: "clientName",
    label: "Imie i nazwisko klienta",
    hint: null,
    required: true,
    group: "Klient",
    type: "text",
  },
  {
    name: "clientAddress",
    label: "Adres klienta",
    hint: "Opcjonalnie",
    required: false,
    group: "Klient",
    type: "text",
  },
  {
    name: "clientEmail",
    label: "Email klienta",
    hint: "Opcjonalnie",
    required: false,
    group: "Klient",
    type: "text",
  },
  // Pojazd
  {
    name: "vehicleDetails",
    label: "Dane pojazdu",
    hint: "Marka, model, pojemność, rok produkcji",
    required: true,
    group: "Pojazd",
    type: "text",
  },
  {
    name: "vehicleVin",
    label: "VIN",
    hint: null,
    required: true,
    group: "Pojazd",
    type: "text",
  },
  {
    name: "assemblyKmCount",
    label: "Stan licznika",
    hint: "Przy montażu cześci",
    required: true,
    group: "Pojazd",
    type: "number",
  },
  {
    name: "dismantlingKmCount",
    label: "Stan licznika",
    hint: "Przy demontażu cześci",
    required: true,
    group: "Pojazd",
    type: "number",
  },
  // Część
  {
    name: "partDetails",
    label: "Nazwa i numer cześci",
    hint: null,
    required: true,
    group: "Część",
    type: "text",
  },
  {
    name: "assemblyDate",
    label: "Data montażu",
    hint: null,
    required: true,
    group: "Część",
    type: "date",
  },
  {
    name: "dismantlingDate",
    label: "Data demontażu",
    hint: null,
    required: true,
    group: "Część",
    type: "date",
  },
  {
    name: "whoAssembled",
    label: "Kto montował",
    hint: null,
    required: true,
    group: "Część",
    type: whoOptions(),
    value: whoOptions()[0].value,
  },
  // Reklamacja
  {
    name: "whoIdentified",
    label: "Kto stwierdził usterkę",
    hint: null,
    required: true,
    group: "Reklamacja",
    type: whoOptions(),
    value: whoOptions()[0].value,
  },
  {
    name: "whenIdentified",
    label: "Kiedy stwierdzono usterkę",
    hint: null,
    required: true,
    group: "Reklamacja",
    type: whenOptions(),
    value: whenOptions()[0].value,
  },
  {
    name: "issueDescription",
    label: "Przyczyna reklamacji",
    hint: "Dokładny opis usterki",
    required: true,
    group: "Reklamacja",
    type: "textarea",
  },
  {
    name: "whoDismantled",
    label: "Kto demontował część",
    hint: null,
    required: true,
    group: "Reklamacja",
    type: whoOptions(),
    value: whoOptions()[0].value,
  },
  {
    name: "returnRealization",
    label: "Sposób realizacji reklamacji",
    hint: null,
    required: true,
    group: "Reklamacja",
    type: realizationOptions(),
    value: realizationOptions()[0].value,
  },
];

const initialState = fieldsConfig.reduce((acc, field) => {
  acc[field.name] = field.value || "";
  return acc;
}, {});

const groupIcons = {
  Klient: <FaUser size={20} style={{ marginRight: "8px" }} />,
  Pojazd: <FaCar size={20} style={{ marginRight: "8px" }} />,
  Część: <FaTools size={20} style={{ marginRight: "8px" }} />,
  Reklamacja: <FaExclamationCircle size={20} style={{ marginRight: "8px" }} />,
};

const groupByCategory = (fields) => {
  return fields.reduce((acc, field) => {
    const { group } = field;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(field);
    return acc;
  }, {});
};

function ComplaintNew() {
  const user = loadUser();
  console.log("Render ComplaintNew");

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialState);
  const [attachments, setAttachments] = useState([]);
  const [touched, setTouched] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const groupedFields = groupByCategory(fieldsConfig);

  const onError = (message) => {
    setMessage(message);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onCancel = () => {
    console.log("ComplaintNew onCancel");
    navigate(-1);
  };

  const validateField = (field) => {
    const value = formData[field.name];
    if (field.required && !value.toString().trim()) {
      return "To pole jest wymagane.";
    }
    if (
      field.name === "dismantlingKmCount" &&
      formData["dismantlingKmCount"] < formData["assemblyKmCount"]
    ) {
      return "Demontaż nie może być przed montażem.";
    }
    if (field.type === "date" && new Date(value) > new Date()) {
      return "Data musi być w przeszłości.";
    }
    if (
      field.name === "dismantlingDate" &&
      new Date(formData["dismantlingDate"]) < new Date(formData["assemblyDate"])
    ) {
      return "Data demontażu nie może być przed montażem.";
    }
    return null;
  };

  const onNew = () => {
    const errors = fieldsConfig
      .map((f) => ({
        name: f.name,
        error: validateField(f),
      }))
      .filter((f) => f.error);

    if (errors.length > 0) {
      const touchedFields = errors.reduce((acc, err) => {
        acc[err.name] = err.error;
        return acc;
      }, {});
      setTouched({ ...touched, ...touchedFields });
      onError("Popraw błedy w formularzu!");
      return;
    }

    let data = {
      ...formData,
      attachments: attachments.map((attachment) => ({
        author: user.phone,
        authorName: user.name,
        name: attachment.file.name,
        data: attachment.base64,
      })),
    };
    data.client = data.client.replace(/\s+/g, ""); // remove spaces
    data.assemblyDate = new Date(data.assemblyDate);
    data.dismantlingDate = new Date(data.dismantlingDate);

    console.log("ComplaintNew onNew", data);

    setLoading(true);
    axios
      .post("complaint", data)
      .then(() => {
        dispatch(updateComplaints());
        navigate("/complaints");
      })
      .catch((err) => onError(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const getInput = (field, error) => {
    const onChange = (e) => {
      const { name, value } = e.target;
      const formatValue = (field, value) => {
        value = value.toUpperCase();
        if (field.name === "client")
          return formatPhoneString(
            value.replace(/[^0-9+]/g, "").replace(/\s+/g, "")
          ).replace(/\s+$/, "");
        return value;
      };
      setFormData({
        ...formData,
        [name]: formatValue(field, value),
      });
    };
    const onBlur = (fieldName) => {
      setTouched({ ...touched, [fieldName]: true });
    };
    if (Array.isArray(field.type)) {
      return (
        <Input
          id={field.name}
          name={field.name}
          type="select"
          value={formData[field.name]}
          onChange={(e) => onChange(e)}
          onBlur={() => onBlur(field.name)}
          invalid={error && error.length}
        >
          {field.type.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    }
    return (
      <Input
        id={field.name}
        name={field.name}
        type={field.type}
        value={formData[field.name]}
        onChange={(e) => onChange(e)}
        onBlur={() => onBlur(field.name)}
        invalid={Boolean(error)}
      />
    );
  };

  const groupElements = Object.keys(groupedFields).map((name) => {
    const icon = groupIcons[name];
    const fields = groupedFields[name].map((field) => {
      const error = touched[field.name] ? validateField(field) : null;
      const input = getInput(field, error);
      return (
        <Col md={6} key={field.name}>
          <FormGroup>
            <Label for={field.name}>
              {field.label} {field.required && "*"}
            </Label>
            {input}
            {error && <FormFeedback>{error}</FormFeedback>}
            {<small className="form-text text-muted">{field.hint}</small>}
          </FormGroup>
        </Col>
      );
    });

    return (
      <Card className="mb-4 shadow-sm" key={name}>
        <CardBody>
          <CardTitle
            tag="h5"
            className="d-flex align-items-center text-primary fw-bold"
          >
            {icon}
            {name}
          </CardTitle>
          <Row>{fields}</Row>
        </CardBody>
      </Card>
    );
  });

  const onAddAttachment = (file) => {
    console.log("ComplaintNew onAddAttachment", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const attachment = {
        file: file,
        base64: reader.result,
      };
      setAttachments((prev) => [...prev, attachment]);
    };
    reader.onerror = (error) => {
      console.error("Error converting to base64:", error);
    };
  };

  const onRemoveAttachment = (index) => {
    console.log("ComplaintNew onRemoveAttachment", index);
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const attachmentElements = attachments.map((attachment, index) => (
    <div key={index} className="d-flex align-items-center mb-2">
      <Card
        style={{
          width: 100,
          height: 100,
          marginRight: 20,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={attachment.base64}
          alt={attachment.name}
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      </Card>
      <span style={{ marginRight: "8px" }}>
        <b>{attachment.file.name}</b>
      </span>
      <Button close onClick={() => onRemoveAttachment(index)} />
    </div>
  ));

  const attachmentListElement =
    attachments.length > 0 ? (
      <div>
        <p>Dodane załączniki:</p>
        {attachmentElements}
      </div>
    ) : (
      <></>
    );

  return (
    <Container className="py-4">
      <CustomAlert
        message={message}
        color="danger"
        dismiss={() => setMessage("")}
      />
      <Row>
        <Col className="mb-3">
          <CustomText size={32} bold>
            Nowa reklamacja
          </CustomText>
          <CustomText color={Colors.quaternary}>
            Wypełnij wszystkie wymagane pola, aby utworzyć nową reklamację.
          </CustomText>
        </Col>
      </Row>
      <Form>
        {groupElements}
        <Card className="mb-4 shadow-sm">
          <CardBody>
            <CardTitle
              tag="h5"
              className="d-flex align-items-center text-primary fw-bold mb-3"
            >
              <FaPaperclip size={20} style={{ marginRight: "8px" }} />
              Załączniki
            </CardTitle>
            <Row>
              <FormGroup>
                {attachmentListElement}
                <div className="mt-4">
                  <CustomButton
                    color="secondary"
                    icon={FaPlus}
                    text={"Dodaj załącznik"}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={(event) => onAddAttachment(event.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
              </FormGroup>
            </Row>
          </CardBody>
        </Card>
        <div className="d-flex justify-content-end gap-2">
          <CustomButton
            color="secondary"
            icon={FaRegTimesCircle}
            text={"Anuluj"}
            onClick={onCancel}
          />
          <CustomButton
            color="primary"
            icon={FaArrowRight}
            text={"Utwórz reklamację"}
            onClick={onNew}
            loading={loading}
          />
        </div>
      </Form>
    </Container>
  );
}

export default ComplaintNew;
