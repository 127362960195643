import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { updateOrders } from "../../redux/notificationsSlice";

import { loadUser } from "../../utils/AuthDataUtils";
import { loadOrdersWithUsers } from "../../utils/AxiosUtils";
import {
  datetimeStringToStringAgo,
  formatPhoneString,
  labelOptions,
  labelToPriority,
  optionValueToLabel,
} from "../../utils/StringUtils";

import { Col, Row } from "reactstrap";
import CustomTable from "../../components/CustomTable";
import CustomOrderDetails from "../../components/CustomOrderDetails";

export default function Dashboard() {
  const user = loadUser();
  console.log("Render Dashboard, user:", user !== null);

  const dispatch = useDispatch();

  const clearTimerRef = useRef();

  const [orders, setOrders] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    const sortOrders = (orders) => {
      return orders.sort((a, b) => {
        const prioA = labelToPriority(a.clientData?.label);
        const prioB = labelToPriority(b.clientData?.label);
        return prioA > prioB || new Date(a.createdAt) - new Date(b.createdAt);
      });
    };

    const reload = () => {
      console.log("Dashboard reload");
      const querry = "page=0&status=NEW";
      loadOrdersWithUsers(querry)
        .then((page) => {
          const sorted = sortOrders(page.content);
          setOrders(sorted);
          setId(sorted.length ? sorted[0].id : null);
        })
        .catch((err) => console.warn(err))
        .finally(() => {
          clearTimerRef.current = setTimeout(reload, 5 * 60 * 1000);
        });
    };

    reload();
    return () => clearTimeout(clearTimerRef.current);
  }, []);

  const onRowClick = (index) => {
    console.log("Dashboard onRowClick", index);
    setId(orders[index].id);
  };

  const onUpdate = (newOrder) => {
    console.log("Orders onUpdate", newOrder);
    dispatch(updateOrders());

    const newOrders = orders.filter((o) => o.id !== newOrder.id);
    setOrders(newOrders);
  };

  const renderClient = (order) => {
    const client = order.clientData;
    if (client) {
      return client.name;
    }
    return formatPhoneString(order.client);
  };

  const isSelected = (item) => {
    return item.id === id;
  };

  const tableLayout = [
    { name: "Nr", key: (o) => o.number },
    { name: "Klient", key: (o) => renderClient(o) },
    {
      name: "Typ klienta",
      key: (o) => optionValueToLabel(labelOptions(), o.clientData?.label),
    },
    {
      name: "Utworzone",
      key: (o) => datetimeStringToStringAgo(o.createdAt),
    },
  ];

  return (
    <Col sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }}>
      <Row>
        <Col sm={6}>
          <Row className="mt-3">
            <CustomTable
              data={orders}
              layout={tableLayout}
              onRowClick={onRowClick}
              isSelected={isSelected}
            />
          </Row>
        </Col>
        <Col sm={6}>
          <CustomOrderDetails id={id} onUpdate={onUpdate} />
        </Col>
      </Row>
    </Col>
  );
}
