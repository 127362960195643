import React, { useEffect, useState } from "react";

import axios from "axios";

import { parseAxiosError } from "../utils/AxiosUtils";
import { loadUser } from "../utils/AuthDataUtils";
import {
  datetimeStringToStringAgo,
  formatPhoneString,
  labelOptions,
  optionValueToLabel,
  parseOrderStatus,
} from "../utils/StringUtils";

import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";

import {
  FaCheck,
  FaSms,
  FaPlus,
  FaEnvelope,
  FaArrowLeft,
  FaCommentAlt,
} from "react-icons/fa";

import LoadingPanel from "./LoadingPanel";
import CustomText from "./CustomText";
import CustomModal from "./CustomModal";
import CustomButton from "./CustomButton";
import CustomAlert from "./CustomAlert";
import CustomUserElement from "./CustomUserElement";
import CustomTextLabelWithCopy from "./CustomTextLabelWithCopy";

import Colors from "../constants/Colors";
import CustomImageViewer from "./CustomImageViewer";

function orEmpty(value) {
  if (!value) {
    return <i>Brak</i>;
  }
  return value;
}

const CustomComplaintDetails = ({ id, onUpdate }) => {
  console.log("Render CustomComplaintDetails");

  const user = loadUser();

  const [order, setOrder] = useState();
  const [seller, setSeller] = useState();
  const [client, setClient] = useState();
  const [vehicle, setVehicle] = useState();
  const [workflowModal, setWorkflowModal] = useState({ isOpen: false });
  const [answer, setAnswer] = useState();
  const [answerModal, setAnswerModal] = useState(false);
  const [comment, setComment] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [commenting, setCommenting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const loadUserData = async (user) => {
      return axios.get("user/" + user);
    };

    const loadUsers = (order) => {
      const userPromises = [];
      if (order.seller) {
        userPromises.push(
          loadUserData(order.seller)
            .then((res) => setSeller(res.data))
            .catch(() => setSeller(null))
        );
      } else {
        setSeller(null);
      }

      if (order.client) {
        userPromises.push(
          loadUserData(order.client)
            .then((res) => setClient(res.data))
            .catch(() => setClient(null))
        );
      }

      Promise.all(userPromises)
        .then(() => {
          setOrder(order);
          if (order.vehicle) {
            return axios.get("vehicle/" + order.vehicle);
          }
        })
        .then((res) => (res ? setVehicle(res.data) : null))
        .finally(() => setLoading(false));
    };

    if (!id) {
      return;
    }

    setLoading(true);
    setVehicle(null);
    axios
      .get("order/" + id)
      .then((res) => loadUsers(res.data))
      .catch((err) => console.log(err));
  }, [id]);

  if (loading) {
    return (
      <div style={{ height: "1500px", marginTop: "200px" }}>
        <LoadingPanel size={60} />
      </div>
    );
  }

  const error = (message) => {
    setMessage(message);
  };

  const toggleAnswerModal = () => {
    setAnswerModal(!answerModal);
  };

  const toggleCommentModal = () => {
    setCommentModal(!commentModal);
  };

  const updateOrder = (newOrder) => {
    console.log("CustomComplaintDetails updateOrder", newOrder);
    setWorkflowModal({ isOpen: false });
    setLoading(true);
    axios
      .put("order/" + order.id, newOrder)
      .then((res) => {
        setOrder(res.data);
        setSeller(newOrder.sellerData);
        onUpdate(newOrder);
      })
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const onAssign = () => {
    console.log("CustomComplaintDetails onAssign");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onAssign onConfirm");
      var newOrder = JSON.parse(JSON.stringify(order));
      newOrder.seller = user.phone;
      newOrder.sellerData = user;
      newOrder.status = "PROCESSING";
      newOrder.processingAt = new Date();
      newOrder.processingBy = user.phone;
      newOrder.closedAt = null;
      newOrder.images = [".."]; // it will be ignored anyway
      updateOrder(newOrder);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz rozpocząć realizacje i przypisać się do tego zamówienia?",
      onConfirm: onConfirm,
      onConfirmText: "Zacznij obsługę",
      onConfirmColor: "success",
    });
  };

  const onDeassign = () => {
    console.log("CustomComplaintDetails onDeassign");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onDeassign onConfirm");
      var newOrder = JSON.parse(JSON.stringify(order));
      newOrder.seller = null;
      newOrder.status = "NEW";
      newOrder.images = [".."]; // it will be ignored anyway
      updateOrder(newOrder);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz przestać realizaować to zamówienie?",
      onConfirm: onConfirm,
      onConfirmText: "Zrezygnuj",
      onConfirmColor: "warning",
    });
  };

  const onClose = () => {
    console.log("CustomComplaintDetails onClose");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onClose onConfirm");
      var newOrder = JSON.parse(JSON.stringify(order));
      newOrder.seller = user.phone;
      newOrder.status = "CLOSED";
      newOrder.closedAt = new Date();
      newOrder.closedBy = user.phone;
      newOrder.images = [".."]; // it will be ignored anyway
      updateOrder(newOrder);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno oznaczyć to zamówienie jako zrealizowane? Pamiętaj o dodaniu odpowiedzi dla klienta!",
      onConfirm: onConfirm,
      onConfirmText: "Zrealizuj",
      onConfirmColor: "success",
    });
  };

  const onReactivate = () => {
    console.log("CustomComplaintDetails onReactivate");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onReactivate onConfirm");
      var newOrder = JSON.parse(JSON.stringify(order));
      newOrder.seller = user.phone;
      newOrder.sellerData = user;
      newOrder.status = "PROCESSING";
      newOrder.processingAt = new Date();
      newOrder.processingBy = user.phone;
      newOrder.closedAt = null;
      newOrder.images = [".."]; // it will be ignored anyway
      updateOrder(newOrder);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz wrócić do realizajci tego zamówienia i się do niego przyposać? Pamiętaj o zmianie odpowiedzi dla klienta!",
      onConfirm: onConfirm,
      onConfirmText: "Wznów",
      onConfirmColor: "primary",
    });
  };

  const onAnswer = () => {
    console.log("CustomComplaintDetails onAnswer", answer);
    toggleAnswerModal();
    var newOrder = JSON.parse(JSON.stringify(order));
    newOrder.answer = answer;
    newOrder.images = [".."]; // it will be ignored anyway
    updateOrder(newOrder);
  };

  const onComment = () => {
    console.log("CustomComplaintDetails onComment", comment);
    toggleCommentModal();
    const data = {
      author: user.phone,
      authorName: user.name,
      text: comment,
    };
    setCommenting(true);
    axios
      .put("order/" + order.id + "/comment", data)
      .then((res) => setOrder(res.data))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setCommenting(false));
  };

  const workflowSteps = [
    {
      status: "NEW",
      label: parseOrderStatus("NEW"),
      color: "secondary",
      buttons: [
        <CustomButton
          color="success"
          icon={FaCheck}
          text={"Przypisz się"}
          onClick={onAssign}
        />,
      ],
    },
    {
      status: "PROCESSING",
      label: parseOrderStatus("PROCESSING"),
      color: "primary",
      buttons: [
        <CustomButton
          color="warning"
          icon={FaArrowLeft}
          text={"Confinij przypisanie"}
          onClick={onDeassign}
        />,
        <CustomButton
          color="success"
          icon={FaCheck}
          text={"Oznacza jako zrealizowane"}
          onClick={onClose}
        />,
      ],
    },
    {
      status: "CLOSED",
      label: parseOrderStatus("CLOSED"),
      color: "success",
      buttons: [
        <CustomButton
          color="warning"
          icon={FaArrowLeft}
          text={"Wznów"}
          onClick={onReactivate}
        />,
      ],
    },
  ];

  const currentStep = workflowSteps.find(
    (step) => step.status === order.status
  );

  const workflowStepElements = workflowSteps.map((step, idx) => {
    const currentIndex = workflowSteps.findIndex(
      (step) => step.status === order.status
    );
    const isActive = idx <= currentIndex;
    return (
      <Col key={step.label} className="d-flex flex-column align-items-center">
        <div
          className={`rounded-circle mb-2 ${
            isActive ? `bg-${step.color}` : "bg-light"
          } d-flex justify-content-center align-items-center`}
          style={{ width: "2rem", height: "2rem" }}
        >
          <CustomText style={{ margin: 0 }} color={Colors.backgroundQuaternary}>
            {idx + 1}
          </CustomText>
        </div>
        <small className={isActive ? `text-${step.color}` : "text-muted"}>
          <b>{step.label}</b>
        </small>
      </Col>
    );
  });

  const vehicleElement = vehicle ? (
    <>
      <CustomText style={{ marginBottom: 8 }} color={Colors.quaternary} bold>
        Informacje o pojeździe
      </CustomText>
      <Row className="mb-1">
        <CustomText bold>Dane pojazdu:</CustomText>
        <CustomText>{vehicle.name}</CustomText>
      </Row>
      <CustomTextLabelWithCopy label={"VIN:"} text={vehicle.vin} />
    </>
  ) : null;

  const answerElement = order.answer ? (
    <ListGroupItem>{order.answer}</ListGroupItem>
  ) : (
    <></>
  );

  const answerModalBody = (
    <Col style={{ paddingRight: 15, paddingLeft: 15 }}>
      <Row>Jaką odpowiedź przesłać do klienta?</Row>
      <Row>
        <Input type="textarea" onChange={(e) => setAnswer(e.target.value)} />
      </Row>
    </Col>
  );

  const comments = order.comments.length ? order.comments : [];
  const commentElements = comments
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((comment, index) => (
      <ListGroupItem key={index}>
        <strong>{comment.authorName}:</strong> {comment.text}{" "}
        <span className="text-muted">
          {datetimeStringToStringAgo(comment.createdAt)}
        </span>
      </ListGroupItem>
    ));

  const commentModalBody = (
    <Col style={{ paddingRight: 15, paddingLeft: 15 }}>
      <Row>Co chcesz przekazać współpracownikom?</Row>
      <Row>
        <Input type="textarea" onChange={(e) => setComment(e.target.value)} />
      </Row>
    </Col>
  );

  return (
    <div className="mt-3">
      <CustomAlert
        message={message}
        color="danger"
        dismiss={() => setMessage("")}
      />
      <CustomModal
        isOpen={workflowModal.isOpen}
        toggle={() => setWorkflowModal({ isOpen: false })}
        title={"Obsługa reklamacji"}
        body={workflowModal.body}
        onConfirmText={workflowModal.onConfirmText}
        onConfirmColor={workflowModal.onConfirmColor}
        onConfirm={workflowModal.onConfirm}
      />
      <CustomModal
        isOpen={answerModal}
        toggle={toggleAnswerModal}
        title={"Odpowiedź do klienta"}
        body={answerModalBody}
        onConfirmText={"Wyślij"}
        onConfirmColor={"primary"}
        onConfirm={onAnswer}
      />
      <CustomModal
        isOpen={commentModal}
        toggle={toggleCommentModal}
        title={"Wewnętrzny komentarz"}
        body={commentModalBody}
        onConfirmText={"Skomentuj"}
        onConfirmColor={"primary"}
        onConfirm={onComment}
      />
      <Card className="mb-3 shadow-sm">
        <CardHeader className="bg-light">
          <Row className="align-items-center">
            <Col sm={12} md={7}>
              <CustomText size={18} style={{ marginBottom: 0 }} bold>
                Zamówienie numer: {order.number}
              </CustomText>
            </Col>
            <Col sm={12} md={5} className="text-md-end mt-2 mt-md-0">
              <div className="d-flex justify-content-end gap-2">
                <Badge className={"px-3 py-2 fs-5"} color={currentStep.color}>
                  {currentStep.label}
                </Badge>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="text-center mb-4">{workflowStepElements}</Row>
          <Row className="mb-3">
            <Col md={6}>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Informacje o kliencie
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Klient:</CustomText>
                <CustomUserElement
                  user={client}
                  phone={order.client}
                  name={order.clientName}
                  alternate={"Brak"}
                />
              </Row>
              <Row className="mb-1">
                <CustomText bold>Telefon:</CustomText>
                <CustomText>{formatPhoneString(order.client)}</CustomText>
              </Row>
              <Row>
                <CustomText bold>Typ klienta:</CustomText>
                <CustomText>
                  {orEmpty(optionValueToLabel(labelOptions(), client.label))}
                </CustomText>
              </Row>
            </Col>
            <Col md={6}>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Obsługa zamówienia
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Przypisane do:</CustomText>
                <CustomUserElement
                  user={seller}
                  phone={order.seller}
                  alternate={"Jeszcze do nikogo"}
                />
              </Row>
              <Row className="mb-1">
                <CustomText bold>Utworzono:</CustomText>
                <CustomText>
                  {datetimeStringToStringAgo(order.createdAt)}
                </CustomText>
              </Row>
            </Col>
          </Row>
          <hr />
          <div className="d-flex flex-wrap gap-2 mb-3">
            {currentStep.buttons}
          </div>
          <hr />
          <Row className="mb-3">
            <CustomImageViewer images={order.images} />
          </Row>
          <hr />
          <Row className="mb-3">
            <Col md={6}>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Informacje o zamówieniu
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Opis:</CustomText>
                <CustomText>{order.description}</CustomText>
              </Row>
            </Col>
            <Col md={6}>{vehicleElement}</Col>
          </Row>
          <hr />
          <div className="mb-3">
            <CustomText style={{ marginBottom: 8 }} color="blue" bold>
              <FaSms /> Odpowiedź do klienta
            </CustomText>
            <ListGroup className="mb-2">{answerElement}</ListGroup>
            <CustomButton
              color="secondary"
              icon={FaEnvelope}
              text={"Odpowiedz"}
              onClick={toggleAnswerModal}
            />
          </div>
          <div>
            <CustomText style={{ marginBottom: 8 }} color="orange" bold>
              <FaCommentAlt /> Wewnętrzne komentarze
            </CustomText>
            <ListGroup className="mb-2">{commentElements}</ListGroup>
            <CustomButton
              color="secondary"
              icon={FaPlus}
              text={"Dodaj komentarz"}
              onClick={toggleCommentModal}
              loading={commenting}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CustomComplaintDetails;
