import React from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, Col, Form, FormGroup, Row, Button } from "reactstrap";
import { loadUser } from "../utils/AuthDataUtils";
import {
  isManager,
  authoritiesToOptions,
  isClient,
} from "../utils/AuthoritiesUtils";
import {
  inlineReturn,
  labelOptions,
  optionValueToLabel,
} from "../utils/StringUtils";

export default function UserCard({ user, target, onDelete }) {
  const currentUser = loadUser();
  const isSelf = user.phone === currentUser.phone;

  const navigate = useNavigate();

  const onUpdate = () => {
    console.log("UserCard onUpdate");
    navigate("/user-update", {
      state: { usedUser: user, target: target },
    });
  };

  const onPassword = () => {
    console.log("UserCard onPassword");
    navigate("/user-password", {
      state: { target: target },
    });
  };

  const authoritiesText = authoritiesToOptions(user.authorities)
    .map((a) => inlineReturn(a.label))
    .join(", ");

  const PasswordButton = () => {
    if (!isSelf) {
      return;
    }
    return (
      <Col xs="auto">
        <Button color="primary" onClick={onPassword}>
          Zmień hasło
        </Button>
      </Col>
    );
  };

  const DeleteButton = () => {
    if (!isSelf && !isManager(currentUser)) {
      return;
    }
    return (
      <Col xs="auto">
        <Button color="danger" onClick={onDelete}>
          Usuń konto
        </Button>
      </Col>
    );
  };

  const ClientLabel = () => {
    if (!isClient(user)) {
      return;
    }
    return (
      <FormGroup>
        Typ klienta
        <h5>{optionValueToLabel(labelOptions(), user.label)}</h5>
      </FormGroup>
    );
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            Numer telefonu
            <h5>{user.phone}</h5>
          </FormGroup>
          <FormGroup>
            Uprawnienia
            <h5>{authoritiesText}</h5>
          </FormGroup>
          <ClientLabel />
          <FormGroup>
            Imie i nazwisko
            <h5>{user.name}</h5>
          </FormGroup>
          <FormGroup>
            Ulica i numer
            <h5>{user.address}</h5>
          </FormGroup>
          <FormGroup>
            Miejscowość
            <h5>{user.city}</h5>
          </FormGroup>
          <FormGroup>
            Kod pocztowy
            <h5>{user.zip}</h5>
          </FormGroup>
        </Form>
        <Row className="align-items-center viewport-height">
          <Col></Col>
          <Col xs="auto">
            <Button color="primary" onClick={onUpdate}>
              Zmień dane
            </Button>
          </Col>
          <PasswordButton />
          <DeleteButton />
        </Row>
      </CardBody>
    </Card>
  );
}
