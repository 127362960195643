import React from "react";

import {
  dateToString,
  formatPhoneString,
  optionValueToLabel,
  realizationOptions,
  whenOptions,
  whoOptions,
} from "../utils/StringUtils";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "MavenPro-Regular",
  src: process.env.PUBLIC_URL + "fonts/MavenPro-Regular.ttf",
});
Font.register({
  family: "MavenPro-Bold",
  src: process.env.PUBLIC_URL + "fonts/MavenPro-Bold.ttf",
});

const placeholder = (lenght) => {
  const character = ".";
  return character.repeat(lenght);
};

const emptyData = {
  number: placeholder(9),
  clientName: placeholder(60),
  clientPhone: placeholder(60),
  clientAddress: placeholder(60),
  clientEmail: placeholder(60),
  partDetails: "nazwa: " + placeholder(60) + ", numer: " + placeholder(30),
  vehicle: "model: " + placeholder(30) + ", VIN: " + placeholder(60),
  dates: placeholder(40) + ", " + placeholder(40),
  kms: placeholder(30) + "km, " + placeholder(30) + "km",
  whoAssembled: whoOptions()
    .map((o) => o.label)
    .join("   "),
  whoAndWhenIdentified:
    whoOptions()
      .map((o) => o.label)
      .join("   ") +
    "\n" +
    whenOptions()
      .map((o) => o.label)
      .join("   "),
  issueDescription: placeholder(150) + "\n" + placeholder(150),
  whoDismantled: whoOptions()
    .map((o) => o.label)
    .join("   "),
  realization: realizationOptions()
    .map((o) => o.label)
    .join("   "),
};

const legalTerms =
  "Ogólnie znana teza głosi, iż użytkownika może rozpraszać zrozumiała zawartość strony, kiedy ten chce zobaczyć sam jej wygląd. Jedną z mocnych stron używania Lorem Ipsum jest to, że ma wiele różnych „kombinacji” zdań, słów i akapitów, w przeciwieństwie do zwykłego: „tekst, tekst, tekst”, sprawiającego, że wygląda to „zbyt czytelnie” po polsku. Wielu webmasterów i designerów używa Lorem Ipsum jako domyślnego modelu tekstu i wpisanie w internetowej wyszukiwarce ‘lorem ipsum’ spowoduje znalezienie bardzo wielu stron, które wciąż są w budowie. Wiele wersji tekstu ewoluowało i zmieniało się przez lata, czasem przez przypadek, czasem specjalnie (humorystyczne wstawki itd).";

export default function CustomComplaintPdf({ complaint, creator }) {
  console.log("Render CustomComplaintPdf", complaint, creator);

  const data = complaint
    ? {
        number: complaint.number,
        clientName: complaint.clientName,
        clientPhone: formatPhoneString(complaint.client),
        clientAddress: complaint.clientAddress
          ? complaint.clientAddress
          : placeholder(60),
        clientEmail: complaint.clientEmail
          ? complaint.clientEmail
          : placeholder(60),
        partDetails: complaint.partDetails,
        vehicle: complaint.vehicleDetails + ", " + complaint.vehicleVin,
        dates:
          dateToString(new Date(complaint.assemblyDate)) +
          ", " +
          dateToString(new Date(complaint.dismantlingDate)),
        kms:
          complaint.assemblyKmCount +
          "km, " +
          complaint.dismantlingKmCount +
          "km",
        whoAssembled: optionValueToLabel(whoOptions(), complaint.whoAssembled),
        whoAndWhenIdentified:
          optionValueToLabel(whoOptions(), complaint.whoIdentified) +
          ", " +
          optionValueToLabel(whenOptions(), complaint.whenIdentified),
        issueDescription: complaint.issueDescription,
        whoDismantled: optionValueToLabel(
          whoOptions(),
          complaint.whoDismantled
        ),
        realization: optionValueToLabel(
          realizationOptions(),
          complaint.returnRealization
        ),
      }
    : emptyData;

  console.log(data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            src={process.env.PUBLIC_URL + "/logo.png"}
            style={{ height: 70, width: 170 }}
          />
          <View style={styles.company}>
            <Text style={styles.companyText}>
              {"MOTO-EXPERT ZIĘBIŃSKI, CZELADZKI SP. J."}
            </Text>
            <Text style={styles.companyText}>{"NIP: 6372204274"}</Text>
            <Text style={styles.companyText}>{"Al. 1000-Lecia 2A"}</Text>
            <Text style={styles.companyText}>{"32-300 Olkusz"}</Text>
            <Text style={styles.companyText}>{"Telefon: +48 511 330 636"}</Text>
          </View>
        </View>
        <View style={styles.title}>
          <Text>{"ZGŁOSZENIE REKLAMACJI NR: " + data.number}</Text>
        </View>
        <View style={styles.section}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <Text style={styles.label}>Imię i nazwisko:</Text>
              <Text style={styles.value}>{data.clientName}</Text>
              <Text style={styles.label}>Numer telefonu:</Text>
              <Text style={styles.value}>{data.clientPhone}</Text>
              <Text style={styles.label}>Adres:</Text>
              <Text style={styles.value}>{data.clientAddress}</Text>
              <Text style={styles.label}>Email:</Text>
              <Text style={styles.value}>{data.clientEmail}</Text>
            </View>
            <View style={{ marginRight: 50 }}>
              <Text style={styles.label}>Osoba przyjmująca reklamację:</Text>
              <Text style={styles.value}>{creator.name}</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>1) Nazwa i numer części:</Text>
          <Text style={styles.value}>{data.partDetails}</Text>
          <Text style={styles.label}>2) Dane pojazdu:</Text>
          <Text style={styles.value}>{data.vehicle}</Text>
          <Text style={styles.label}>3) Data montażu i demontażu części:</Text>
          <Text style={styles.value}>{data.dates}</Text>
          <Text style={styles.label}>
            4) Stan licznika przy montażu i demontażu części:
          </Text>
          <Text style={styles.value}>{data.kms}</Text>
          <Text style={styles.label}>5) Kto montował część:</Text>
          <Text style={styles.value}>{data.whoAssembled}</Text>
          <Text style={styles.label}>6) Kto i kiedy stwierdził usterkę:</Text>
          <Text style={styles.value}>{data.whoAndWhenIdentified}</Text>
          <Text style={styles.label}>7) Przyczyna reklamacji:</Text>
          <Text style={styles.value}>{data.issueDescription}</Text>
          <Text style={styles.label}>8) Kto demontował częśc:</Text>
          <Text style={styles.value}>{data.whoDismantled}</Text>
          <Text style={styles.label}>9) Sposób realizacji reklamacji:</Text>
          <Text style={styles.value}>{data.realization}</Text>
        </View>
        <View style={{ flexGrow: 1 }}></View>
        <View style={styles.signature}>
          <Text style={styles.value}>Podpis składającego reklamację</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Regulamin reklamacji</Text>
        <View style={styles.section}>
          <Text style={styles.disclimer}>{legalTerms}</Text>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontFamily: "MavenPro-Regular",
    padding: 50,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  company: {
    width: 200,
    marginTop: 8,
    textAlign: "center",
  },
  companyText: {
    fontSize: 10,
  },
  title: {
    marginTop: 20,
    textAlign: "center",
    fontFamily: "MavenPro-Bold",
    fontSize: 16,
  },
  clientSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    marginTop: 20,
  },
  label: {
    fontFamily: "MavenPro-Bold",
    fontSize: 10,
    marginBottom: 2,
  },
  value: {
    fontSize: 12,
    marginBottom: 10,
  },
  disclimer: {
    fontSize: 12,
    textAlign: "center",
  },
  signature: {
    fontSize: 12,
    textAlign: "right",
    marginRight: 50,
  },
});
