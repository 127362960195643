import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import { parseAxiosError } from "../utils/AxiosUtils";
import { loadUser } from "../utils/AuthDataUtils";
import {
  datetimeStringToStringAgo,
  dateToString,
  formatPhoneString,
  optionValueToLabel,
  parseComplaintStatus,
  realizationOptions,
  timeLeftTillNow,
  whenOptions,
  whoOptions,
} from "../utils/StringUtils";

import { pdf } from "@react-pdf/renderer";

import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormGroup,
  Input,
  Badge,
  Button,
  Container,
} from "reactstrap";

import {
  FaPrint,
  FaCheck,
  FaSms,
  FaPaperclip,
  FaPlus,
  FaTrash,
  FaEnvelope,
  FaArrowLeft,
  FaRegTimesCircle,
} from "react-icons/fa";

import LoadingPanel from "./LoadingPanel";
import CustomComplaintPdf from "./CustomComplaintPdf";
import CustomAttachmentPdf from "./CustomAttachmentPdf";
import CustomText from "./CustomText";
import CustomModal from "./CustomModal";
import CustomButton from "./CustomButton";
import CustomAlert from "./CustomAlert";
import CustomUserElement from "./CustomUserElement";
import CustomTextLabelWithCopy from "./CustomTextLabelWithCopy";

import Colors from "../constants/Colors";
import CustomImageViewer from "./CustomImageViewer";

const smsAnswers = {
  answerAccepted: {
    short: "Reklamacja uznana",
    message:
      "Dzień dobry!\nReklamacja nr {number} została uznana. Więcej informacji dostępne pod numerem +48511330636, wew. 3.\nMiłego dnia życzy zespół MOTO-EXPERT!",
  },
  answerDismissed: {
    short: "Reklamacja oddalona",
    message:
      "Dzień dobry!\nReklamacja nr {number} została oddalona. Więcej informacji dostępne pod numerem +48511330636, wew. 3.\nMiłego dnia życzy zespół MOTO-EXPERT!",
  },
  answerForwarded: {
    short: "Reklamacja wysłana do producenta",
    message:
      "Dzień dobry!\nReklamacja nr {number} została oddalona i wysłana do producenta w celu ponownego rozpatrzenia. Więcej informacji dostępne pod numerem +48511330636, wew. 3.\nMiłego dnia życzy zespół MOTO-EXPERT!",
  },
};

const CustomComplaintDetails = ({ id, onUpdate }) => {
  console.log("Render CustomComplaintDetails");

  const fileInputRef = useRef(null);

  const user = loadUser();

  const [complaint, setComplaint] = useState();
  const [creator, setCreator] = useState();
  const [client, setClient] = useState();
  const [seller, setSeller] = useState();
  const [workflowModal, setWorkflowModal] = useState({ isOpen: false });
  const [smsModal, setSmsModal] = useState(false);
  const [smsToSend, setSmsToSend] = useState();
  const [smsSending, setSmsSending] = useState(false);
  const [viewModal, setViewModal] = useState({ isOpen: false });
  const [removeModal, setRemoveModal] = useState(false);
  const [attachmentToRemove, setAttachmentToRemove] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const loadUserData = async (user) => {
      return axios.get("user/" + user);
    };

    const loadUsers = (complaint) => {
      const userPromises = [];
      userPromises.push(
        loadUserData(complaint.createdBy)
          .then((res) => setCreator(res.data))
          .catch(() => setCreator(null))
      );
      userPromises.push(
        loadUserData(complaint.client)
          .then((res) => setClient(res.data))
          .catch(() => setClient(null))
      );
      if (complaint.seller) {
        userPromises.push(
          loadUserData(complaint.seller)
            .then((res) => setSeller(res.data))
            .catch(() => setSeller(null))
        );
      } else {
        setSeller(null);
      }

      Promise.all(userPromises)
        .then(() => setComplaint(complaint))
        .finally(() => setLoading(false));
    };

    if (!id) {
      return;
    }

    setLoading(true);
    axios
      .get("complaint/" + id)
      .then((res) => loadUsers(res.data))
      .catch((err) => error(parseAxiosError(err)));
  }, [id]);

  if (loading) {
    return (
      <div style={{ height: "1500px", marginTop: "200px" }}>
        <LoadingPanel size={60} />
      </div>
    );
  }

  const error = (message) => {
    setMessage(message);
  };

  const toggleSmsModal = () => {
    setSmsModal(!smsModal);
  };

  const toggleRemoveModal = () => {
    setRemoveModal(!removeModal);
  };

  const setSmsMessage = (answer) => {
    const smsText = answer.message.replace("{number}", complaint.number);
    setSmsToSend(smsText);
  };

  const updateComplaint = (newComplaint) => {
    console.log("CustomComplaintDetails updateComplaint", newComplaint);
    setWorkflowModal({ isOpen: false });
    setLoading(true);
    axios
      .put("complaint/" + complaint.id, newComplaint)
      .then((res) => {
        setComplaint(res.data);
        setSeller(newComplaint.sellerData);
        newComplaint.expiredAt = res.data.expiredAt;
        onUpdate(newComplaint);
      })
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const onAssign = () => {
    console.log("CustomComplaintDetails onAssign");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onAssign onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.seller = user.phone;
      complaintData.sellerData = user;
      complaintData.status = "PROCESSING";
      complaintData.processingAt = new Date();
      complaintData.processingBy = user.phone;
      complaintData.sentAt = null;
      complaintData.closedAt = null;
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz obsługiwać tą reklamację?",
      onConfirm: onConfirm,
      onConfirmText: "Zacznij obsługę",
      onConfirmColor: "success",
    });
  };

  const onDeassign = () => {
    console.log("CustomComplaintDetails onDeassign");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onDeassign onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.seller = null;
      complaintData.status = "NEW";
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz przestać obsługiwać tą reklamację?",
      onConfirm: onConfirm,
      onConfirmText: "Zrezygnuj",
      onConfirmColor: "warning",
    });
  };

  const onProceed = () => {
    console.log("CustomComplaintDetails onProceed");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onDeassign onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.status = "SENT";
      complaintData.sentAt = new Date();
      complaintData.sentBy = user.phone;
      complaintData.sellerData = seller;
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz oznaczyć tę reklamację jako wysłaną do hurtowni?",
      onConfirm: onConfirm,
      onConfirmText: "Oznacz",
      onConfirmColor: "success",
    });
  };

  const onUnproceed = () => {
    console.log("CustomComplaintDetails onUnproceed");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onDeassign onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.status = "PROCESSING";
      complaintData.sellerData = seller;
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz confnąć oznaczenie tej reklamacji jako wysłanej?",
      onConfirm: onConfirm,
      onConfirmText: "Confnij",
      onConfirmColor: "warning",
    });
  };

  const onFinish = () => {
    console.log("CustomComplaintDetails onFinish");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onFinish onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.status = "CLOSED";
      complaintData.closedAt = new Date();
      complaintData.closedBy = user.phone;
      complaintData.sellerData = seller;
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz zakończyć obsługę tej reklamacji?",
      onConfirm: onConfirm,
      onConfirmText: "Zakończ",
      onConfirmColor: "success",
    });
  };

  const onReactivate = () => {
    console.log("CustomComplaintDetails onReactivate");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onReactivate onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      if (complaint.seller) {
        complaintData.status = "SENT";
        complaintData.sellerData = seller;
      } else {
        complaintData.status = "NEW";
        complaintData.sellerData = null;
      }
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz wznowić obsługę tej reklamacji?",
      onConfirm: onConfirm,
      onConfirmText: "Wznów",
      onConfirmColor: "warning",
    });
  };

  const onCancel = () => {
    console.log("CustomComplaintDetails onCancel");
    const onConfirm = () => {
      console.log("CustomComplaintDetails onCancel onConfirm");
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.status = "CANCELLED";
      complaintData.cancelledAt = new Date();
      complaintData.cancelledBy = user.phone;
      updateComplaint(complaintData);
    };
    setWorkflowModal({
      isOpen: true,
      body: "Czy napewno chcesz wycofać tą reklamacje?",
      onConfirm: onConfirm,
      onConfirmText: "Wycofaj",
      onConfirmColor: "danger",
    });
  };

  const onPrint = async () => {
    console.log("CustomComplaintDetails onPrint");
    const blob = await pdf(
      <CustomComplaintPdf complaint={complaint} creator={creator} />
    ).toBlob();
    const pdfWindow = window.open();
    pdfWindow.location.href = URL.createObjectURL(blob);
  };

  const onSendSms = () => {
    console.log("CustomComplaintDetails onSendSms", smsToSend);
    setSmsModal(false);
    if (!smsToSend) {
      error("SMS nie możeby być pusty!");
      return;
    }
    const data = {
      author: user.phone,
      authorName: user.name,
      text: smsToSend,
    };
    setSmsSending(true);
    axios
      .put("complaint/" + complaint.id + "/sms", data)
      .then((res) => setComplaint(res.data))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setSmsSending(false));
  };

  const onViewAttachment = (attachment) => {
    console.log("CustomComplaintDetails onViewAttachment", attachment.name);
    setViewModal({
      isOpen: true,
      title: attachment.name,
      data: attachment.data,
    });
  };

  const onPrintAttachment = async (attachment) => {
    console.log("CustomComplaintDetails onPrintAttachment", attachment.name);
    const blob = await pdf(
      <CustomAttachmentPdf attachment={attachment} />
    ).toBlob();
    const pdfWindow = window.open();
    pdfWindow.location.href = URL.createObjectURL(blob);
  };

  const onAddAttachment = (file) => {
    console.log("CustomComplaintDetails onAddAttachment", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const attachment = {
        author: user.phone,
        authorName: user.name,
        name: file.name,
        data: reader.result,
      };
      var complaintData = JSON.parse(JSON.stringify(complaint));
      complaintData.attachments.push(attachment);
      complaintData.sellerData = seller;
      updateComplaint(complaintData);
    };
    reader.onerror = (error) => {
      console.error("Error converting to base64:", error);
    };
  };

  const onRemoveAttachment = (index) => {
    console.log("CustomComplaintDetails onRemoveAttachment", index);
    setAttachmentToRemove(index);
    toggleRemoveModal();
  };

  const onConfirmRemoveAttachment = () => {
    console.log(
      "CustomComplaintDetails onConfirmRemoveAttachment",
      attachmentToRemove
    );
    toggleRemoveModal();
    var complaintData = JSON.parse(JSON.stringify(complaint));
    complaintData.attachments.splice(attachmentToRemove, 1);
    complaintData.sellerData = seller;
    updateComplaint(complaintData);
  };

  const workflowSteps = [
    {
      status: "NEW",
      label: parseComplaintStatus("NEW"),
      color: "secondary",
      buttons: [
        <CustomButton
          color="success"
          icon={FaCheck}
          text={"Przypisz się"}
          onClick={onAssign}
        />,
        <CustomButton
          color="danger"
          icon={FaRegTimesCircle}
          text={"Wycofaj"}
          onClick={onCancel}
        />,
      ],
    },
    {
      status: "PROCESSING",
      label: parseComplaintStatus("PROCESSING"),
      color: "primary",
      buttons: [
        <CustomButton
          color="warning"
          icon={FaArrowLeft}
          text={"Confinij przypisanie"}
          onClick={onDeassign}
        />,
        <CustomButton
          color="success"
          icon={FaCheck}
          text={"Oznacza jako wysłaną"}
          onClick={onProceed}
        />,
        <CustomButton
          color="danger"
          icon={FaRegTimesCircle}
          text={"Wycofaj"}
          onClick={onCancel}
        />,
      ],
    },
    {
      status: "SENT",
      label: parseComplaintStatus("SENT"),
      color: "warning",
      buttons: [
        <CustomButton
          color="warning"
          icon={FaArrowLeft}
          text={"Confinij wysłanie"}
          onClick={onUnproceed}
        />,
        <CustomButton
          color="success"
          icon={FaCheck}
          text={"Zakończ"}
          onClick={onFinish}
        />,
      ],
    },
    {
      status: "CLOSED",
      label: parseComplaintStatus("CLOSED"),
      color: "success",
      buttons: [
        <CustomButton
          color="warning"
          icon={FaArrowLeft}
          text={"Wznów"}
          onClick={onReactivate}
        />,
      ],
    },
    {
      status: "CANCELLED",
      label: parseComplaintStatus("CANCELLED"),
      color: "danger",
      buttons: [],
    },
  ];

  const currentStep = workflowSteps.find(
    (step) => step.status === complaint.status
  );

  const smsModalBody = (
    <Col style={{ paddingRight: 15, paddingLeft: 15 }}>
      <Row>Jaką wiadomość chcesz wysłać do klienta?</Row>
      <FormGroup tag="fieldset">
        <Row className="mt-3 align-items-center viewport-height">
          <Col sm={"auto"}>
            <Input
              name="radio"
              type="radio"
              onChange={() => setSmsMessage(smsAnswers.answerAccepted)}
            />
          </Col>
          <Col>{smsAnswers.answerAccepted.short}</Col>
        </Row>
        <Row className="mt-3 align-items-center viewport-height">
          <Col sm={"auto"}>
            <Input
              name="radio"
              type="radio"
              onChange={() => setSmsMessage(smsAnswers.answerDismissed)}
            />
          </Col>
          <Col>{smsAnswers.answerDismissed.short}</Col>
        </Row>
        <Row className="mt-3 align-items-center viewport-height">
          <Col sm={"auto"}>
            <Input
              name="radio"
              type="radio"
              onChange={() => setSmsMessage(smsAnswers.answerForwarded)}
            />
          </Col>
          <Col>{smsAnswers.answerForwarded.short}</Col>
        </Row>
      </FormGroup>
    </Col>
  );

  const viewModalBody = (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <img
        src={viewModal.data}
        alt={viewModal.name}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          height: "auto",
          width: "auto",
          objectFit: "contain",
        }}
      />
    </Container>
  );

  const printButton = complaint.formImage ? (
    <></>
  ) : (
    <CustomButton
      color="primary"
      icon={FaPrint}
      text={"Formularz"}
      onClick={onPrint}
      outline
    />
  );

  const workflowStepElements =
    complaint.status === "CANCELLED" ? (
      <></>
    ) : (
      workflowSteps.slice(0, -1).map((step, idx) => {
        const currentIndex = workflowSteps.findIndex(
          (step) => step.status === complaint.status
        );
        const isActive = idx <= currentIndex;
        return (
          <Col
            key={step.label}
            className="d-flex flex-column align-items-center"
          >
            <div
              className={`rounded-circle mb-2 ${
                isActive ? `bg-${step.color}` : "bg-light"
              } d-flex justify-content-center align-items-center`}
              style={{ width: "2rem", height: "2rem" }}
            >
              <CustomText
                style={{ margin: 0 }}
                color={Colors.backgroundQuaternary}
              >
                {idx + 1}
              </CustomText>
            </div>
            <small className={isActive ? `text-${step.color}` : "text-muted"}>
              <b>{step.label}</b>
            </small>
          </Col>
        );
      })
    );

  const complaintDataElement = !complaint.formImage ? (
    <>
      <Row className="mb-3">
        <Col className="mb-3" md={6}>
          <CustomText
            style={{ marginBottom: 8 }}
            color={Colors.quaternary}
            bold
          >
            Informacje o pojeździe
          </CustomText>
          <CustomTextLabelWithCopy
            label="Dane pojazdu:"
            text={complaint.vehicleDetails}
          />
          <CustomTextLabelWithCopy label="VIN:" text={complaint.vehicleVin} />
          <CustomTextLabelWithCopy
            label="Stan licznika przy montażu:"
            text={complaint.assemblyKmCount + "km"}
          />
          <CustomTextLabelWithCopy
            label="Stan licznika przy demontażu:"
            text={complaint.dismantlingKmCount + "km"}
          />
        </Col>
        <Col md={6}>
          <CustomText
            style={{ marginBottom: 8 }}
            color={Colors.quaternary}
            bold
          >
            Informacje o cześci
          </CustomText>
          <CustomTextLabelWithCopy
            label="Nazwa i numer cześci:"
            text={complaint.partDetails}
          />
          <CustomTextLabelWithCopy
            label="Data montażu:"
            text={dateToString(new Date(complaint.assemblyDate))}
          />
          <CustomTextLabelWithCopy
            label="Data demontażu:"
            text={dateToString(new Date(complaint.dismantlingDate))}
          />
          <CustomTextLabelWithCopy
            label="Kto montował:"
            text={optionValueToLabel(whoOptions(), complaint.whoAssembled)}
          />
        </Col>
        <Col md={6}>
          <CustomText
            style={{ marginBottom: 8 }}
            color={Colors.quaternary}
            bold
          >
            Informacje o reklamacji
          </CustomText>
          <CustomTextLabelWithCopy
            label={"Przyczyna:"}
            text={complaint.issueDescription}
          />
          <CustomTextLabelWithCopy
            label={"Sposób realizacji:"}
            text={optionValueToLabel(
              realizationOptions(),
              complaint.returnRealization
            )}
          />
        </Col>
        <Col md={6}>
          <CustomText
            style={{ marginBottom: 8 }}
            color={Colors.backgroundQuaternary}
            bold
          >
            {"Placeholder :)"}
          </CustomText>
          <CustomTextLabelWithCopy
            label={"Kto stwierdził usterkę:"}
            text={optionValueToLabel(whoOptions(), complaint.whoIdentified)}
          />
          <CustomTextLabelWithCopy
            label={"Kiedy stwierdzono usterkę:"}
            text={optionValueToLabel(whenOptions(), complaint.whenIdentified)}
          />
          <CustomTextLabelWithCopy
            label={"Kto demontował część:"}
            text={optionValueToLabel(whoOptions(), complaint.whoDismantled)}
          />
        </Col>
      </Row>
      <hr />
    </>
  ) : (
    <></>
  );

  const smses = complaint.smses ? complaint.smses : [];
  const smsMessageElements = smses.map((message, index) => (
    <ListGroupItem key={index}>
      <strong>{message.authorName}:</strong> {message.text}{" "}
      <span className="text-muted">
        {datetimeStringToStringAgo(message.createdAt)}
      </span>
    </ListGroupItem>
  ));

  const attachments = complaint.attachments ? complaint.attachments : [];
  const attachmentElements = attachments.map((attachment, index) => (
    <ListGroupItem
      key={index}
      className="d-flex justify-content-between align-items-center"
    >
      <div>
        <Link onClick={() => onViewAttachment(attachment)}>
          {attachment.name}
        </Link>
      </div>
      <div className="d-flex">
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => onPrintAttachment(attachment)}
          className="d-flex align-items-center me-2"
        >
          <FaPrint className="me-1" /> Drukuj
        </Button>
        <Button
          color="danger"
          size="sm"
          onClick={() => onRemoveAttachment(index)}
          className="d-flex align-items-center"
        >
          <FaTrash className="me-1" /> Usuń
        </Button>
      </div>
    </ListGroupItem>
  ));

  const attachementImages = complaint.attachments.map((a) => a.data);
  const images = complaint.formImage
    ? [complaint.formImage, ...attachementImages]
    : [...attachementImages];
  const galleryElement = images.length ? (
    <>
      <Row className="mb-3">
        <CustomImageViewer images={images} />
      </Row>
      <hr />
    </>
  ) : (
    <></>
  );

  return (
    <div className="mt-3">
      <CustomAlert
        message={message}
        color="danger"
        dismiss={() => setMessage("")}
      />
      <CustomModal
        isOpen={workflowModal.isOpen}
        toggle={() => setWorkflowModal({ isOpen: false })}
        title={"Obsługa reklamacji"}
        body={workflowModal.body}
        onConfirmText={workflowModal.onConfirmText}
        onConfirmColor={workflowModal.onConfirmColor}
        onConfirm={workflowModal.onConfirm}
      />
      <CustomModal
        isOpen={smsModal}
        toggle={toggleSmsModal}
        title={"Nowa wiadmość SMS"}
        body={smsModalBody}
        onConfirmText={"Wyślij"}
        onConfirmColor={"primary"}
        onConfirm={onSendSms}
      />
      <CustomModal
        isOpen={viewModal.isOpen}
        toggle={() => setViewModal({ isOpen: false })}
        title={viewModal.title}
        body={viewModalBody}
      />
      <CustomModal
        isOpen={removeModal}
        toggle={toggleRemoveModal}
        title={"Usuwanie załącznika"}
        body={"Czy na pewno chcesz usunąć ten załącznik?"}
        onConfirmText={"Usuń"}
        onConfirmColor={"danger"}
        onConfirm={onConfirmRemoveAttachment}
      />
      <Card className="mb-3 shadow-sm">
        <CardHeader className="bg-light">
          <Row className="align-items-center">
            <Col sm={12} md={7}>
              <CustomText size={18} style={{ marginBottom: 0 }} bold>
                Reklamacja numer: {complaint.number}
              </CustomText>
            </Col>
            <Col sm={12} md={5} className="text-md-end mt-2 mt-md-0">
              <div className="d-flex justify-content-end gap-2">
                <Badge className={"px-3 py-2 fs-5"} color={currentStep.color}>
                  {currentStep.label}
                </Badge>
                {printButton}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="text-center mb-4">{workflowStepElements}</Row>
          <Row className="mb-3">
            <Col md={6}>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Informacje o reklamacji
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Utworzona przez:</CustomText>
                <CustomUserElement
                  user={creator}
                  phone={complaint.createdBy}
                  alternate={"Brak"}
                />
              </Row>
              <Row>
                <CustomText bold>Przypisane do:</CustomText>
                <CustomUserElement
                  user={seller}
                  phone={complaint.seller}
                  alternate={"Jeszcze do nikogo"}
                />
              </Row>
            </Col>
            <Col md={6}>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Obsługa reklamacji
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Utworzono:</CustomText>
                <CustomText>
                  {datetimeStringToStringAgo(complaint.createdAt)}
                </CustomText>
              </Row>
              <Row>
                <CustomText bold>Czas do przedawnienia:</CustomText>
                <CustomText>{timeLeftTillNow(complaint.expiredAt)}</CustomText>
              </Row>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <CustomText
                style={{ marginBottom: 8 }}
                color={Colors.quaternary}
                bold
              >
                Informacje o kliencie
              </CustomText>
              <Row className="mb-1">
                <CustomText bold>Klient:</CustomText>
                <CustomUserElement
                  user={client}
                  phone={complaint.client}
                  name={complaint.clientName}
                  alternate={"Brak"}
                />
              </Row>
              <Row>
                <CustomText bold>Telefon:</CustomText>
                <CustomText>{formatPhoneString(complaint.client)}</CustomText>
              </Row>
            </Col>
          </Row>
          <hr />
          <div className="d-flex flex-wrap gap-2 mb-3">
            {currentStep.buttons}
          </div>
          <hr />
          {galleryElement}
          {complaintDataElement}
          <div className="mb-3">
            <CustomText style={{ marginBottom: 8 }} color="blue" bold>
              <FaSms /> Komunikacja SMS
            </CustomText>
            <ListGroup className="mb-2">{smsMessageElements}</ListGroup>
            <CustomButton
              color="secondary"
              icon={FaEnvelope}
              text={"Wyślij SMS"}
              onClick={() => setSmsModal(true)}
              loading={smsSending}
            />
          </div>
          <div>
            <CustomText style={{ marginBottom: 8 }} color="orange" bold>
              <FaPaperclip /> Załączniki
            </CustomText>
            <ListGroup className="mb-2">{attachmentElements}</ListGroup>
            <CustomButton
              color="secondary"
              icon={FaPlus}
              text={"Dodaj załącznik"}
              onClick={() => fileInputRef.current.click()}
            />
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={(event) => onAddAttachment(event.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CustomComplaintDetails;
