import React from "react";

import { Row } from "reactstrap";

function StoreSelection() {
  console.log("Render StoreSelection");

  return (
    <Row className="ms-3 me-3 mt-5 mb-4 justify-content-center">
      <Row className="mt-5" />
      <Row className="me-2 mt-5 justify-content-center">
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          style={{ height: 130, width: 360 }}
          alt="MOTO-EXPERT"
        />
      </Row>
      <Row className="mt-5" />
      <a href="https://play.google.com/store/apps/details?id=online.motoexpert">
        <Row className="mt-5 justify-content-center">
          <img
            src={process.env.PUBLIC_URL + "/google.png"}
            style={{ height: 65, width: 240 }}
            alt="google"
          />
        </Row>
      </a>
      <a href="https://apps.apple.com/us/app/moto-expert/id6740438318">
        <Row className="mt-3 justify-content-center">
          <img
            src={process.env.PUBLIC_URL + "/apple.png"}
            style={{ height: 65, width: 240 }}
            alt="apple"
          />
        </Row>
      </a>
    </Row>
  );
}

export default StoreSelection;
