import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./redux/.store";

import { setupAxiosInterceptor } from "./utils/AxiosUtils";
import {
  clearAuthData,
  storeAuthData,
  loadUser,
  attemptTokenRefresh,
} from "./utils/AuthDataUtils";

import Navigation from "./navigation/Navigation";
import LoadingScreen from "./components/LoadingScreen";
import NavigationBar from "./navigation/NavigationBar";

import PrivacyPolicy from "./screens/PrivacyPolicy";
import StoreSelection from "./screens/StoreSelection";

export default function App() {
  const user = loadUser();
  console.log("Render App, user:", user !== null);

  const [logged, setLogged] = useState(user !== null);
  const [loading, setLoading] = useState(true);

  const location = window.location.pathname;

  useEffect(() => {
    setupAxiosInterceptor(() => setLogged(false));
    attemptTokenRefresh()
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (location.includes("privacy-policy")) {
    return <PrivacyPolicy />;
  }

  if (location.includes("qr-code")) {
    return <StoreSelection />;
  }

  const onLoggedIn = (user, token, refreshToken) => {
    console.log("App logged in, logged:", logged);
    storeAuthData(user, token, refreshToken);
    setLogged(true);
  };

  const onLoggedOut = () => {
    console.log("App logged out, logged", logged);
    clearAuthData();
    setLogged(false);
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <NavigationBar onLoggedOut={onLoggedOut} />
        <Navigation onLoggedIn={onLoggedIn} onLoggedOut={onLoggedOut} />
      </BrowserRouter>
    </Provider>
  );
}
