import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "MavenPro-Regular",
  src: process.env.PUBLIC_URL + "fonts/MavenPro-Regular.ttf",
});
Font.register({
  family: "MavenPro-Bold",
  src: process.env.PUBLIC_URL + "fonts/MavenPro-Bold.ttf",
});

const CustomAttachmentPdf = ({ attachment }) => {
  console.log("Render CustomAttachmentPdf", attachment);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            src={process.env.PUBLIC_URL + "/logo.png"}
            style={{ height: 70, width: 170 }}
          />
          <View style={styles.company}>
            <Text style={styles.companyText}>
              {"MOTO-EXPERT ZIĘBIŃSKI, CZELADZKI SP. J."}
            </Text>
            <Text style={styles.companyText}>{"NIP: 6372204274"}</Text>
            <Text style={styles.companyText}>{"Al. 1000-Lecia 2A"}</Text>
            <Text style={styles.companyText}>{"32-300 Olkusz"}</Text>
            <Text style={styles.companyText}>{"Telefon: +48 511 330 636"}</Text>
          </View>
        </View>
        <Image style={styles.image} src={attachment.data} />
      </Page>
    </Document>
  );
};

export default CustomAttachmentPdf;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontFamily: "MavenPro-Regular",
    padding: 50,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  company: {
    width: 200,
    marginTop: 8,
    textAlign: "center",
  },
  companyText: {
    fontSize: 10,
  },
  image: {
    marginTop: 50,
    objectFit: "contain",
    maxHeight: 600,
  },
});
