import React from "react";
import Colors from "../constants/Colors";

function CustomText(props) {
  var style = { ...defaultStyle, ...props.style };
  if (props.size) {
    style.fontSize = props.size;
  }
  if (props.color) {
    style.color = props.color;
  }
  const content = props.bold ? <b>{props.children}</b> : <>{props.children}</>;
  return <p style={style}>{content}</p>;
}

export default CustomText;

const defaultStyle = {
  fontSize: 16,
  color: Colors.tertiary,
  marginTop: 0,
  marginBottom: 0,
  paddingTop: 0,
  paddingBottom: 0,
};
