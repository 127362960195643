import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { updateComplaints } from "../../redux/notificationsSlice";

import {
  loadComplaintsWithUsers,
  parseAxiosError,
} from "../../utils/AxiosUtils";
import { loadUser } from "../../utils/AuthDataUtils";
import {
  complaintStatusOptions,
  formatPhoneString,
  inlineReturn,
  parseComplaintStatus,
  timeLeftTillNow,
} from "../../utils/StringUtils";

import { pdf } from "@react-pdf/renderer";

import { Button, Col, Input, Row } from "reactstrap";
import Select from "react-select";

import { FaPlus, FaPrint } from "react-icons/fa";

import CustomTable from "../../components/CustomTable";
import CustomComplaintPdf from "../../components/CustomComplaintPdf";
import CustomComplaintDetails from "../../components/CustomComplaintDetails";
import CustomPagination from "../../components/CustomPagination";

const defaultStatuses = [
  { value: "NEW", label: parseComplaintStatus("NEW") },
  { value: "PROCESSING", label: parseComplaintStatus("PROCESSING") },
];

function Complaints() {
  const user = loadUser();
  console.log("Render Complaints, user:", user !== null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchLock, setSearchLock] = useState("");
  const [statues, setStatuses] = useState(defaultStatuses);
  const [statuesLock, setStatuesLock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [complaints, setComplaints] = useState([]);
  const [id, setId] = useState();

  const error = (message) => {
    console.warn(message);
  };

  const loadFirstPage = useCallback(
    (onSuccess) => {
      let params = ["page=0"];
      if (search && search.length) {
        params.push("key=" + search);
      }
      if (statues && statues.length) {
        params.push("status=" + statues.map((s) => s.value).join(","));
      }
      setSearchLock(search);
      setStatuesLock(statues);
      setPage(0);
      setLoading(true);
      loadComplaintsWithUsers(params.join("&"))
        .then((res) => onSuccess(res))
        .catch((err) => error(parseAxiosError(err)))
        .finally(() => setLoading(false));
    },
    [search, statues]
  );

  const handlePageLoaded = (newPage) => {
    console.log("Orders handleNewPage", newPage);
    setComplaints(newPage.content);
    setTotalPages(newPage.totalPages);
    setId(newPage.content.length ? newPage.content[0].id : null);
  };

  useEffect(() => loadFirstPage(handlePageLoaded), [loadFirstPage]);

  const onPageChange = (number) => {
    console.log("Orders onPageChange", number);
    let params = ["page=" + number];
    if (searchLock && searchLock.length) {
      params.push("key=" + searchLock);
    }
    if (statuesLock && statuesLock.length) {
      params.push("status=" + statuesLock.join(","));
    }
    setPage(number);
    setLoading(true);
    loadComplaintsWithUsers(params.join("&"))
      .then((res) => handlePageLoaded(res))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const onNew = () => {
    console.log("Complaints onNew");
    navigate("/complaint-new");
  };

  const onEmptyForm = async () => {
    console.log("Complaints onEmptyForm");
    const blob = await pdf(<CustomComplaintPdf creator={user} />).toBlob();
    const pdfWindow = window.open();
    pdfWindow.location.href = URL.createObjectURL(blob);
  };

  const onRowClick = (index) => {
    console.log("Complaints onRowClick", index);
    setId(complaints[index].id);
  };

  const onUpdate = (newComplaint) => {
    console.log("Complaints onUpdate", newComplaint);
    dispatch(updateComplaints());
    const newComplaints = complaints
      .map((o) => {
        if (o.id === newComplaint.id) {
          if (!newComplaint.sellerData) {
            newComplaint.sellerData = o.sellerData;
          }
          newComplaint.clientData = o.clientData;
          return newComplaint;
        }
        return o;
      })
      .sort((a, b) => new Date(a.expiredAt) - new Date(b.expiredAt));
    setComplaints(newComplaints);
  };

  const isSelected = (item) => {
    return item.id === id;
  };

  const onStatusesChange = (newStatuses) => {
    setStatuses(newStatuses);
  };

  const renderClient = (complaint) => {
    const client = complaint.clientData;
    if (client) {
      return client.name;
    }
    return formatPhoneString(complaint.client);
  };

  const renderSeller = (complaint) => {
    if (!complaint.seller) {
      return "";
    }
    const seller = complaint.sellerData;
    if (seller) {
      return seller.name;
    }
    return formatPhoneString(complaint.seller);
  };

  const renderStatus = (status, text) => {
    let color = "success";
    if (status === "NEW") color = "secondary";
    if (status === "PROCESSING") color = "primary";
    if (status === "SENT") color = "warning";
    if (status === "CANCELLED") color = "danger";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className={`badge bg-${color} px-2 py-1 fs-7`}>{text}</span>
      </div>
    );
  };

  const tableLayout = [
    { name: "Nr", key: (o) => inlineReturn(o.number) },
    {
      name: " ",
      key: (o) => renderStatus(o.status, parseComplaintStatus(o.status)),
    },
    { name: "Klient", key: (o) => renderClient(o) },
    { name: "Pracownik", key: (o) => renderSeller(o) },
    {
      name: "Do przedawnienia",
      key: (o) => timeLeftTillNow(o.expiredAt),
    },
  ];

  return (
    <Col sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }}>
      <Row>
        <Col sm={6}>
          <Row className="mt-3">
            <Col>
              <Input
                type="text"
                placeholder={"Fragment nr tel. albo imienia i nazwiska klienta"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                className="d-flex align-items-center"
                onClick={onNew}
              >
                <FaPlus className="me-1" /> Nowa reklamacja
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Select
                isMulti
                options={complaintStatusOptions()}
                onChange={onStatusesChange}
                value={statues}
                placeholder={"Status reklamacji"}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                outline
                className="d-flex align-items-center pe-3 ps-3"
                onClick={onEmptyForm}
              >
                <FaPrint className="me-2" /> Pusty formularz
              </Button>
            </Col>
          </Row>
          <Row className="mt-lg-3">
            <CustomTable
              loading={loading}
              data={complaints}
              layout={tableLayout}
              onRowClick={onRowClick}
              isSelected={isSelected}
            />
            <CustomPagination
              visible={!loading && complaints.length}
              current={page}
              total={totalPages}
              onChange={onPageChange}
            />
          </Row>
        </Col>
        <Col sm={6}>
          <CustomComplaintDetails id={id} onUpdate={onUpdate} />
        </Col>
      </Row>
    </Col>
  );
}

export default Complaints;
