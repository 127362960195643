import React, { useEffect, useState } from "react";

import { loadUser } from "../../utils/AuthDataUtils";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { Col, Input, Row } from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomButton from "../../components/CustomButton";
import LoadingPanel from "../../components/LoadingPanel";

export default function Adverts() {
  const user = loadUser();
  console.log("Render Adverts, user:", user !== null);

  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("danger");

  const error = (message) => {
    setMessage(message);
    setMessageType("danger");
  };

  const success = (message) => {
    setMessage(message);
    setMessageType("success");
  };

  useEffect(() => {
    axios
      .get("advert/latest")
      .then((res) => fetch(res.data.image))
      .then((res) => res.blob())
      .then((res) => setImage(res))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  }, []);

  const upload = (base64) => {
    const data = {
      image: base64,
    };
    setUploading(true);
    axios
      .post("advert", data)
      .then(() => success("Nowa reklama ustawiona!"))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setUploading(false));
  };

  const parseFile = () => {
    if (!image) {
      error("Brakuje zdjęcia!");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      upload(reader.result);
    };
    reader.onerror = function () {
      error("Nie udało się przetwożyć zdjęcia!");
    };
  };

  const onSelected = (file) => {
    console.log("Adverts onSelected", file);
    if (file.type.split("/")[0] !== "image") {
      error("To nie jest zdjęcie");
      return;
    }
    setMessage("");
    setImage(file);
  };

  const Image = () => {
    if (loading) {
      return <LoadingPanel size={60} />;
    }
    if (!image) {
      return;
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="not found"
          style={{ width: "100%", height: "70vh", objectFit: "contain" }}
          src={URL.createObjectURL(image)}
        />
      </div>
    );
  };

  return (
    <Col sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }}>
      <Row className="mt-lg-3">
        <CustomAlert
          message={message}
          color={messageType}
          dismiss={() => setMessage("")}
        />
        <Row className="mt-2">
          <Image />
        </Row>
        <Row className="mt-2">
          <Col>
            <Input
              type="file"
              name="myImage"
              onChange={(event) => onSelected(event.target.files[0])}
            />
          </Col>
          <Col xs="auto">
            <CustomButton
              text={"Ustaw"}
              color="primary"
              onClick={parseFile}
              loading={uploading}
            />
          </Col>
        </Row>
      </Row>
      <Row className="mt-3" />
    </Col>
  );
}
