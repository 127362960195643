import React, { useState } from "react";

import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";

const CustomImageViewer = ({ images }) => {
  const [index, setIndex] = useState(-1);

  const slides = images.map((src) => ({ src }));

  return (
    <div className="d-flex flex-wrap gap-2">
      {images.map((base64, i) => (
        <div
          style={{
            width: 120,
            height: 120,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            key={i}
            src={base64}
            alt={`${i}`}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              cursor: "pointer",
              objectFit: "contain",
            }}
            onClick={() => setIndex(i)}
          />
        </div>
      ))}
      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
      />
    </div>
  );
};

export default CustomImageViewer;
