import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  selectComplaints,
  selectOrders,
  selectOrdersFetchStatus,
  updateComplaints,
  updateOrders,
} from "../redux/notificationsSlice";

import { loadUser } from "../utils/AuthDataUtils";
import { initialPath, isMarketer, isSeller } from "../utils/AuthoritiesUtils";

import { BrowserView, MobileView } from "react-device-detect";

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
} from "reactstrap";

const updateTitleAndFavicon = (title, favicon) => {
  document.title = title;
  let link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = favicon;
  }
};

export default function NavigationBar({ onLoggedOut }) {
  const user = loadUser();
  console.log("Render NavigationBar, user:", user !== null);

  const dispatch = useDispatch();

  const ordersBadge = useSelector(selectOrders);
  const ordersBadgeFetchStatus = useSelector(selectOrdersFetchStatus);

  const complaintsBadge = useSelector(selectComplaints);
  const complaintsFetchStatus = useSelector(selectOrdersFetchStatus);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("NavigationBar useEffect fetch");
    if (ordersBadgeFetchStatus === "idle") {
      dispatch(updateOrders());
    }
    if (complaintsFetchStatus === "idle") {
      dispatch(updateComplaints());
    }
  }, [dispatch, ordersBadgeFetchStatus, complaintsFetchStatus]);

  useEffect(() => {
    console.log("NavigationBar useEffect update", ordersBadge, complaintsBadge);

    const intervalCallback = () => {
      console.log("NavigationBar useEffect update timerCallback");
      if (document.title === "MOTO-EXPERT") {
        updateTitleAndFavicon("UWAGA, tematy czekają!", "logo192.png");
      } else {
        updateTitleAndFavicon("MOTO-EXPERT", "favicon.ico");
      }
    };

    if (ordersBadge || complaintsBadge) {
      console.log("NavigationBar useEffect update aaa");

      const intervalId = setInterval(intervalCallback, 1000);
      return () => clearInterval(intervalId);
    } else {
      updateTitleAndFavicon("MOTO-EXPERT", "favicon.ico");
    }
  }, [ordersBadge, complaintsBadge]);

  const onLogout = () => {
    console.log("NavigationBar onLogout");
    close();
    onLoggedOut();
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const CustomNavItem = ({ to, text, onClick, condition }) => {
    if (!condition) {
      return;
    }
    const renderBadge =
      (text === "Reklamacje" && complaintsBadge) ||
      (text === "Nowe zamówienia" && ordersBadge);
    const marginLeft = text === "Reklamacje" ? 77 : 126;
    const badge = renderBadge ? (
      <div
        style={{
          position: "absolute",
          marginTop: -24,
          marginLeft: marginLeft,
        }}
      >
        <style>{`
        @keyframes pulsate {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.4);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
        <div
          style={{
            height: 9,
            width: 9,
            borderRadius: 5,
            backgroundColor: "red",
            border: "1px solid white",
            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.3)",
            animation: "pulsate 1s infinite",
          }}
        />
      </div>
    ) : null;
    return (
      <NavItem className="px-1">
        <NavLink tag={Link} to={to} onClick={onClick}>
          {text}
          {badge}
        </NavLink>
      </NavItem>
    );
  };

  let brand;
  if (user) {
    brand = (
      <NavbarBrand tag={Link} to={initialPath(user)} onClick={close}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          height="40"
          alt="DIAGME"
        />
      </NavbarBrand>
    );
  } else {
    brand = (
      <NavbarBrand tag={Link} to="/" onClick={close}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          height="40"
          alt="DIAGME"
        />
      </NavbarBrand>
    );
  }

  let main;
  if (user) {
    main = (
      <Nav className="ml" navbar>
        <CustomNavItem
          to="/dashboard"
          text="Nowe zamówienia"
          onClick={close}
          condition={isSeller(user)}
        />
        <CustomNavItem
          to="/orders"
          text="Historia zamówień"
          onClick={close}
          condition={isSeller(user)}
        />
        <CustomNavItem
          to="/complaints"
          text="Reklamacje"
          onClick={close}
          condition={isSeller(user)}
        />
        <CustomNavItem
          to="/users"
          text="Użytkownicy"
          onClick={close}
          condition={true}
        />
        <CustomNavItem
          to="/adverts"
          text="Reklamy"
          onClick={close}
          condition={isMarketer(user)}
        />
      </Nav>
    );
  } else {
    main = <Nav className="ml" navbar></Nav>;
  }

  let control;
  if (user) {
    control = (
      <Nav className="ms-auto" navbar>
        <CustomNavItem
          to="/profile"
          text="Profil"
          onClick={close}
          condition={true}
        />
        <CustomNavItem
          to="/"
          text="Wyloguj"
          onClick={onLogout}
          condition={true}
        />
      </Nav>
    );
  } else {
    control = <Nav className="ms-auto" navbar></Nav>;
  }

  return (
    <>
      <MobileView>
        <Navbar color="light" light expand="lg">
          {brand}
          <NavbarToggler onClick={toggle} />
          <Collapse navbar isOpen={isOpen}>
            {main}
            {control}
          </Collapse>
        </Navbar>
      </MobileView>
      <BrowserView>
        <Navbar color="light" light expand="md">
          {brand}
          {main}
          {control}
        </Navbar>
      </BrowserView>
    </>
  );
}
