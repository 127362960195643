import React, { useState } from "react";

import { Row, Container, Tooltip } from "reactstrap";

import { FaRegCopy } from "react-icons/fa";

import CustomText from "./CustomText";

import Colors from "../constants/Colors";

const CustomTextLabelWithCopy = ({ label, text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCopy = () => {
    navigator.clipboard.writeText(text);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
  };
  return (
    <Row className="mb-1">
      <CustomText bold>{label}</CustomText>
      <Container className="d-flex align-items-center">
        <CustomText>{text}</CustomText>
        <span
          id="copy-icon"
          onClick={onCopy}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            color: Colors.quaternary,
          }}
        >
          <FaRegCopy />
        </span>
      </Container>
      <Tooltip isOpen={tooltipOpen} target="copy-icon" placement="top">
        {"Skopiowane!"}
      </Tooltip>
    </Row>
  );
};

export default CustomTextLabelWithCopy;
