import React from "react";
import { Button, Spinner } from "reactstrap";

const CustomButton = ({
  color = "primary",
  icon: Icon,
  text,
  onClick,
  loading = false,
  outline = false,
}) => {
  return (
    <Button
      color={color}
      onClick={onClick}
      disabled={loading}
      className="d-flex align-items-center"
      outline={outline}
    >
      {loading ? (
        <Spinner size="sm" className="me-2" />
      ) : (
        Icon && <Icon className="me-2" />
      )}
      {text}
    </Button>
  );
};

export default CustomButton;
