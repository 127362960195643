import React from "react";
import { Link } from "react-router-dom";

import { formatPhoneString } from "../utils/StringUtils";

function CustomUserElement({ user, name, phone, alternate }) {
  if (!phone) {
    return <i>{alternate}</i>;
  }
  if (!user) {
    if (name) {
      return <text>{name}</text>;
    }
    return <i>{formatPhoneString(phone)}</i>;
  }
  return (
    <Link to={"/user-details"} state={{ phone: user.phone }}>
      {user.name}
    </Link>
  );
}

export default CustomUserElement;
