import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";
import { isSeller } from "../../utils/AuthoritiesUtils";
import { loadUser } from "../../utils/AuthDataUtils";

import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import UserForm from "../../components/UserForm";

export default function UserNew() {
  const currentUser = loadUser();
  console.log("Render UserNew, user:", currentUser !== null);

  const navigate = useNavigate();

  let auth = [];
  if (isSeller(currentUser)) {
    auth.push({ authority: "ROLE_CLIENT" });
  }

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [newUser, setNewUser] = useState({ authorities: auth });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onNext = () => {
    newUser.phone = phone;
    newUser.password = password;

    console.log("UserNew onNext", newUser);

    if (!newUser.phone) {
      error("Brakuje numeru telefon!");
      return;
    }
    if (!newUser.password) {
      error("Brakuje hasła!");
      return;
    }
    if (!Array.isArray(newUser.authorities) || !newUser.authorities.length) {
      error("Brakuje uprawnień");
      return;
    }
    if (!newUser.name) {
      error("Brakuje imienia i nazwiska!");
      return;
    }
    if (!newUser.address) {
      error("Brakuje ulicy i numeru!");
      return;
    }
    if (!newUser.city) {
      error("Brakuje miasta!");
      return;
    }
    if (!newUser.zip) {
      error("Brakuje kodu pocztowego!");
      return;
    }

    setMessage("");
    setLoading(true);
    axios
      .post("user", newUser)
      .then(() => navigate("/users"))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const initialForm = (
    <Form>
      <FormGroup>
        <Label>Numer telefonu</Label>
        <Input
          name="phone"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Hasło</Label>
        <Input
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormGroup>
    </Form>
  );

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <UserForm
            title={"Nowy użytkownik"}
            user={newUser}
            initialForm={initialForm}
            loading={loading}
            onChange={(u) => setNewUser(u)}
            onConfirm={onNext}
            onConfirmText={"Stwórz"}
          />
        </Card>
      </Row>
      <Row className="mt-3" />
    </Col>
  );
}
