import React, { useEffect, useState } from "react";

import {
  authoritiesOptions,
  authoritiesToOptions,
  isClient,
  optionsToAuthorities,
  isManager,
} from "../utils/AuthoritiesUtils";
import { labelOptions, stringOrEmpty } from "../utils/StringUtils";
import { loadUser } from "../utils/AuthDataUtils";

import {
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";

import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";

export default function UserForm({
  title,
  user,
  initialForm,
  loading,
  onChange,
  onConfirm,
  onConfirmText,
}) {
  const currentUser = loadUser();

  const [authorities, setAuthorities] = useState(user.authorities);
  const [label, setLabel] = useState(user.label);
  const [name, setName] = useState(stringOrEmpty(user.name));
  const [address, setAddress] = useState(stringOrEmpty(user.address));
  const [city, setCity] = useState(stringOrEmpty(user.city));
  const [zip, setZip] = useState(stringOrEmpty(user.zip));

  const navigate = useNavigate();

  useEffect(() => {
    let newUser = user;
    newUser.authorities = authorities;
    newUser.label = label;
    if (!label) delete newUser.label;
    newUser.name = name;
    newUser.address = address;
    newUser.city = city;
    newUser.zip = zip;
    onChange(newUser);
  }, [user, onChange, authorities, label, name, address, city, zip]);

  const AuthoritiesSelector = () => {
    if (!isManager(currentUser)) {
      return;
    }

    const valueToAuth = (newValue) => {
      const newAuth = optionsToAuthorities(newValue);
      setAuthorities(newAuth);
    };

    const current = authoritiesToOptions(authorities);

    return (
      <Form>
        <FormGroup>
          <Label>Uprawnienia</Label>
          <Select
            isMulti={true}
            options={authoritiesOptions()}
            defaultValue={current}
            onChange={valueToAuth}
          />
        </FormGroup>
      </Form>
    );
  };

  const LabelSelector = () => {
    if (!isClient({ authorities })) {
      return;
    }

    const options = labelOptions();
    const current = options.filter((opt) => {
      return opt.value === label;
    });

    return (
      <Form>
        <FormGroup>
          <Label>Typ klienta</Label>
          <Select
            isMulti={false}
            options={labelOptions()}
            defaultValue={current}
            onChange={(sel) => setLabel(sel.value)}
          />
        </FormGroup>
      </Form>
    );
  };

  return (
    <CardBody>
      <CardTitle tag="h5">{title}</CardTitle>
      {initialForm}
      {AuthoritiesSelector()}
      {LabelSelector()}
      <Form>
        <FormGroup>
          <Label>Imię i nazwisko</Label>
          <Input
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
      </Form>
      <Form>
        <FormGroup>
          <Label>Ulica i numer</Label>
          <Input
            name="address"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </FormGroup>
      </Form>
      <Form>
        <FormGroup>
          <Label>Miejscowość</Label>
          <Input
            name="city"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </FormGroup>
      </Form>
      <Form>
        <FormGroup>
          <Label>Kod pocztowy</Label>
          <Input
            name="zip"
            id="zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </FormGroup>
      </Form>
      <Row className="align-items-center viewport-height">
        <Col></Col>
        <Col xs="auto">
          <CustomButton
            text={"Anuluj"}
            color="secondary"
            onClick={() => navigate(-1)}
            loading={loading}
          />
        </Col>
        <Col xs="auto">
          <CustomButton
            text={onConfirmText}
            color="primary"
            onClick={onConfirm}
            loading={loading}
          />
        </Col>
      </Row>
    </CardBody>
  );
}
