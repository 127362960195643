import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { parseAxiosError } from "../utils/AxiosUtils";

const initialState = {
  orders: false,
  ordersFetchStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  ordersFetchError: null,
  complaints: false,
  complaintsFetchStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  complaintsFetchError: null,
};

export const updateOrders = createAsyncThunk(
  "notifications/updateOrders",
  async (_, { rejectWithValue }) => {
    console.log("notificationsSlice updateOrders");
    try {
      const response = await axios.get("order/all?status=NEW");
      return response.data.content.length > 0;
    } catch (error) {
      return rejectWithValue(parseAxiosError(error));
    }
  }
);

export const updateComplaints = createAsyncThunk(
  "notifications/updateComplaints",
  async (_, { rejectWithValue }) => {
    console.log("notificationsSlice updateComplaints");
    try {
      const response = await axios.get("complaint/all?status=NEW");
      return response.data.content.filter(
        (c) => new Date(c.expiredAt).getFullYear() < 2500
      ).length;
    } catch (error) {
      return rejectWithValue(parseAxiosError(error));
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // updateOrders
      .addCase(updateOrders.pending, (state) => {
        state.ordersFetchStatus = "loading";
      })
      .addCase(updateOrders.rejected, (state, action) => {
        state.ordersFetchStatus = "failed";
        state.ordersFetchError = action.payload;
      })
      .addCase(updateOrders.fulfilled, (state, action) => {
        state.ordersFetchStatus = "succeeded";
        state.orders = action.payload;
      })
      // updateComplaints
      .addCase(updateComplaints.pending, (state) => {
        state.complaintsFetchStatus = "loading";
      })
      .addCase(updateComplaints.rejected, (state, action) => {
        state.complaintsFetchStatus = "failed";
        state.complaintsFetchError = action.payload;
      })
      .addCase(updateComplaints.fulfilled, (state, action) => {
        state.complaintsFetchStatus = "succeeded";
        state.complaints = action.payload;
      });
  },
});

export const selectOrders = (state) => state.notifications.orders;
export const selectOrdersFetchStatus = (state) =>
  state.notifications.ordersFetchStatus;

export const selectComplaints = (state) => state.notifications.complaints;
export const selectComplaintsFetchStatus = (state) =>
  state.notifications.complaintsFetchStatus;

export default notificationsSlice.reducer;
