export function inlineReturn(string) {
  return string;
}

export function stringOrEmpty(str) {
  return str ? str : "";
}

export function dateToString(d) {
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear()
  );
}

export function datetimeToString(d) {
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear() +
    ", " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2)
  );
}

export function datetimeToStringAgo(date) {
  const now = new Date();
  const seconds = (now - date.getTime()) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  if (minutes < 1) return "Teraz";
  if (hours < 1) return parseInt(minutes).toString() + " min. temu";
  if (hours < 24) return parseInt(hours).toString() + " godz. temu";
  return datetimeToString(date);
}

export function datetimeStringToStringAgo(dateString) {
  const date = new Date(dateString);
  return datetimeToStringAgo(date);
}

export function timeLeftTillNow(startDateString) {
  const now = new Date();
  const startDate = new Date(startDateString);

  if (startDate.getFullYear() > 2500) {
    return "Zakończona"; // complaint CLOSED
  }

  const diffMs = startDate - now;
  if (diffMs <= 0) {
    return "Czas już minął!";
  }

  const diffMins = Math.floor(diffMs / (1000 * 60));
  const days = Math.floor(diffMins / (60 * 24));
  const hours = Math.floor((diffMins % (60 * 24)) / 60);

  return `${days} dni, ${hours} godz.`;
}

export function formatPhoneString(phone) {
  phone = phone.slice(0, 3) + " " + phone.slice(3);
  phone = phone.slice(0, 7) + " " + phone.slice(7);
  phone = phone.slice(0, 11) + " " + phone.slice(11);
  return phone;
}

export function statusOptions() {
  return [
    { value: "NEW", label: parseOrderStatus("NEW") },
    { value: "PROCESSING", label: parseOrderStatus("PROCESSING") },
    { value: "CLOSED", label: parseOrderStatus("CLOSED") },
  ];
}

export function complaintStatusOptions() {
  return [
    { value: "NEW", label: parseComplaintStatus("NEW") },
    { value: "PROCESSING", label: parseComplaintStatus("PROCESSING") },
    { value: "SENT", label: parseComplaintStatus("SENT") },
    { value: "CLOSED", label: parseComplaintStatus("CLOSED") },
  ];
}

export function labelOptions() {
  return [
    { value: "WHOLESALER", label: "Hurtownik" },
    { value: "RETAILER", label: "Detalista" },
  ];
}

export function whoOptions() {
  return [
    { value: "USER", label: "Użytkownik" },
    { value: "WORKSHOP", label: "Warsztat samochodowy" },
  ];
}

export function whenOptions() {
  return [
    { value: "BEFORE_ASSEMBLY", label: "Przed montażem" },
    { value: "DURING_ASSEMBLY", label: "W trakcie montażu" },
    { value: "DURING_EXPLOITATION", label: "W trakcie eksploatacji" },
  ];
}

export function realizationOptions() {
  return [
    { value: "EXCHANGE", label: "Wymiana towaru" },
    { value: "REPAIR", label: "Naprawa towaru" },
    { value: "REFUND", label: "Zwrot gtówki" },
  ];
}

export function parseOrderStatus(status) {
  if (status === "NEW") return "Odebrane";
  if (status === "PROCESSING") return "W realizacji";
  if (status === "CLOSED") return "Zrealizowane";
}

export function parseComplaintStatus(status) {
  if (status === "NEW") return "Nowa";
  if (status === "PROCESSING") return "W realizacji";
  if (status === "SENT") return "Wysłana";
  if (status === "CLOSED") return "Zakończona";
  if (status === "CANCELLED") return "Wycofana";
}

export function optionValueToLabel(source, value) {
  if (!value) return null;
  return source.filter((o) => o.value === value)[0].label;
}

export function optionsToValues(options) {
  return options.map((e) => e.value);
}

export function labelToPriority(label) {
  if (label === "WHOLESALER") return 1;
  return 2; // RETAILER or none
}
